import { Model } from "@models/data/model";
import { tryInstantiateAll } from "@models/data/type-resolver";
import { Zone } from "@models/data/zone";

export class Batiment extends Model {
  nom: string;
  disposeZones: Zone[];
  imageName: string;

  constructor(data: any) {
    super(data);
    this.nom = data.nom;
    this.disposeZones = tryInstantiateAll(data.disposeZones);
    this.imageName = data.imageName;
  }
}
