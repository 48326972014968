import { Model } from "@models/data/model";

export class SiteTraitement extends Model {
    code: string;
    name: string;

    constructor(data: any) {
        super(data);
        this.code = data.code;
        this.name = data.name;
    }
}
