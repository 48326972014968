import { Implementation } from "@models/data/implementation";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";

export class ReachableSchema extends Model {
  name: string;
  type: string;
  schema: string;
  ids: any;
  possedeImplementation: Implementation;

  constructor(data: any) {
    super(data);
    this.name = data.name;
    this.type = data.type;
    this.schema = data.schema;
    this.ids = data.ids;
    this.possedeImplementation = tryInstantiate(data.possedeImplementation);
  }
}
