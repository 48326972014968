import { LotTechnique } from "@models/data/lot-technique";
import { Model } from "@models/data/model";
import { tryInstantiateAll } from "@models/data/type-resolver";

export class AdR extends Model {
  code: string;
  etiquette: string;
  emballage: string;
  label: string;
  estClasseDansLotTechniques: LotTechnique[];

  constructor(data: any) {
    super(data);
    this.code = data.code;
    this.etiquette = data.etiquette;
    this.emballage = data.emballage;
    this.label = data.label;
    this.estClasseDansLotTechniques = tryInstantiateAll(
      data.estClasseDansLotTechniques,
    );
  }
}
