export type Adapter<T> = {
  serialize: (value: T) => string;
  parse: (value: string) => T;
};

const notImplemented = (objectName: string, functionName: string) => () => {
  throw Error(`${objectName} don't implement ${functionName}`);
};

export abstract class AbstractAdapter<T> implements Adapter<T> {
  parseIfPresent = (value?: string) => {
    if (value == null) return value;
    return this.parse(value);
  };

  serializeIfPresent = (value?: T): undefined | null | string => {
    if (value === null) return null;
    if (value === undefined) return undefined;
    return this.serialize(value);
  };

  parse = notImplemented("Adapter", "parse") as (value: string) => T;
  serialize = notImplemented("Adapter", "serialize") as (value: T) => string;
}
