import { BusinessStation } from "@models/data/businessstation";
import { DetectionEvent } from "@models/data/detectionevent";
import { Implementation } from "@models/data/implementation";
import { Manager } from "@models/data/manager";
import { Model } from "@models/data/model";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";

export type StationStatus = "UNKNOWN" | "CONNECTED" | "DISCONNECTED" | "BUSY" | "READY";

export class Station extends Model {
  name: string;
  description: string;
  configuration: string;
  workflow: string;
  manageManager: Manager;
  possedeImplementation: Implementation;
  lieeBusinessStation: BusinessStation;
  lieeDetectionEvents: DetectionEvent[];
  status: StationStatus;

  constructor(data: any) {
    super(data);
    this.name = data.name;
    this.description = data.description;
    this.configuration = data.configuration;
    this.workflow = data.workflow;
    this.manageManager = tryInstantiate(data.manageManager);
    this.possedeImplementation = tryInstantiate(data.possedeImplementation);
    this.lieeBusinessStation = tryInstantiate(data.lieeBusinessStation);
    this.lieeDetectionEvents = tryInstantiateAll(data.lieeDetectionEvents);
    this.status = data.status ?? "UNKNOWN";
  }
}
