import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { InventaireEntry } from "@models/data/inventaire-entry";
import { Model } from "@models/data/model";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";
import { User } from "@models/data/user";
import { Zone } from "@models/data/zone";
import { InventaireStatus } from "@models/enums/inventaire-status";

export class Inventaire extends Model {
  dateScan: DateModel;
  dateClosed: DateModel | null;
  nbTagScannes: bigint;
  nbTagTheoriques: bigint;
  scanUser: User;
  closedUser: User | null;
  zone: Zone;
  entries: InventaireEntry[];
  status: InventaireStatus;

  constructor(data: any) {
    super(data);
    this.dateScan = ADAPTERS["DateModel"].parseIfPresent(
      data.dateScan,
    ) as DateModel;
    this.nbTagScannes = data.nbTagScannes;
    this.nbTagTheoriques = data.nbTagTheoriques;
    this.scanUser = tryInstantiate(data.scanUser);
    this.closedUser = data.closedUser === null ? null : tryInstantiate<User>(data.closedUser);
    this.dateClosed = data.dateClosed === null ? null : ADAPTERS["DateModel"].parseIfPresent(
        data.dateClosed,
    ) as DateModel;
    this.zone = tryInstantiate(data.zone);
    this.entries = tryInstantiateAll(
      data.entries,
    );
    this.status = data.status;
  }
}
