import { Adapter } from "@models/adapters/adapter";
import { dateAdapter } from "@models/adapters/date-adapter";
import { jsonAdapter } from "@models/adapters/json-adapter";

const ADAPTERS = {
  DateModel: dateAdapter,
  Json: jsonAdapter,
} as const;

const getAdapter = (key: string) => {
  if (key in ADAPTERS) return (ADAPTERS as Record<string, Adapter<any>>)[key];
  throw new Error(`No Adapter found for ${key}`);
};

export { ADAPTERS, getAdapter };
