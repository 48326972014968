import { AlleyColumn } from "@models/data/alley-column";
import { Model } from "@models/data/model";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";
import { Zone } from "@models/data/zone";

export class Alley extends Model {
    name: string;
    alleyColumns: AlleyColumn[];
    zone: Zone;
    percentFilled: number;
    thresholdTonnage: number;
    currentTonnage: number;

    constructor(data: any) {
        super(data);
        this.name = data.name;
        this.alleyColumns = tryInstantiateAll<AlleyColumn>(data.alleyColumns);
        this.zone = tryInstantiate<Zone>(data.zone);
        this.percentFilled = data.percentFilled;
        this.thresholdTonnage = data.thresholdTonnage;
        this.currentTonnage = data.currentTonnage;
    }
}
