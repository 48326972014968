import { ConstructorType } from "@models/constructor-type";
import { LotTechnique } from "@models/data/lot-technique";
import { Model } from "@models/data/model";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";
import { Zone } from "@models/data/zone";

export class Atelier extends Model {
  type: string;
  codeAffectation: string;
  identifiantImport: bigint;
  code: string;
  libelle: string;
  libelleAffectation: string;
  lieALotTechniques: LotTechnique[];
  zone: Zone;

  constructor(data: ConstructorType<Atelier>) {
    super(data);
    this.type = data.type;
    this.codeAffectation = data.codeAffectation;
    this.identifiantImport = data.identifiantImport;
    this.code = data.code;
    this.libelle = data.libelle;
    this.libelleAffectation = data.libelleAffectation;
    this.lieALotTechniques = tryInstantiateAll<LotTechnique>(data.lieALotTechniques);
    this.zone = tryInstantiate<Zone>(data.zone);
  }
}
