import { Implementation } from "@models/data/implementation";
import { Model } from "@models/data/model";
import { tryInstantiateAll } from "@models/data/type-resolver";

export class Driver extends Model {
  artifactId: string;
  groupId: string;
  version: string;
  possedeImplementations: Implementation[];

  constructor(data: any) {
    super(data);
    this.artifactId = data.artifactId;
    this.groupId = data.groupId;
    this.version = data.version;
    this.possedeImplementations = tryInstantiateAll(
      data.possedeImplementations,
    );
  }
}
