import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Alerte } from "@models/data/alerte";
import { Asset } from "@models/data/asset";
import { InventaireEntry } from "@models/data/inventaire-entry";
import { LotTechnique } from "@models/data/lot-technique";
import { Mouvement } from "@models/data/mouvement";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";
import { Zone } from "@models/data/zone";

export class GroupeLotsTechnique extends Asset {
  code: string;
  creationDate: DateModel;
  concerneAlertes: Alerte[];
  estTraitementFinalZone: Zone;
  estDansZone: Zone;
  inventaires: InventaireEntry[];
  effectueMouvements: Mouvement[];
  contientLotTechniques: LotTechnique[];
  dateArchivage: DateModel | null;

  constructor(data: any) {
    super(data);
    this.code = data.code;
    this.creationDate = ADAPTERS["DateModel"].parseIfPresent(
      data.creationDate,
    ) as DateModel;
    this.concerneAlertes = tryInstantiateAll(data.concerneAlertes);
    this.estTraitementFinalZone = tryInstantiate(data.estTraitementFinalZone);
    this.estDansZone = tryInstantiate(data.estDansZone);
    this.inventaires = tryInstantiateAll(data.contientInventaire);
    this.effectueMouvements = tryInstantiateAll(data.effectueMouvements);
    this.contientLotTechniques = tryInstantiateAll(data.contientLotTechniques);
    this.dateArchivage = data.dateArchivage === null ? null :  ADAPTERS["DateModel"].parseIfPresent(
        data.dateArchivage,
    ) as DateModel;
  }
}
