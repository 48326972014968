import { Driver } from "@models/data/driver";
import { Model } from "@models/data/model";
import { ReachableSchema } from "@models/data/reachableschema";
import { Station } from "@models/data/station";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";

export class Implementation extends Model {
  schema: string;
  defaultConfiguration: string;
  defaultWorkflow: string;
  name: string;
  possedeReachableSchemas: ReachableSchema[];
  possedeDriver: Driver;
  possedeStations: Station[];

  constructor(data: any) {
    super(data);
    this.schema = data.schema;
    this.defaultConfiguration = data.defaultConfiguration;
    this.defaultWorkflow = data.defaultWorkflow;
    this.name = data.name;
    this.possedeReachableSchemas = tryInstantiateAll(
      data.possedeReachableSchemas,
    );
    this.possedeDriver = tryInstantiate(data.possedeDriver);
    this.possedeStations = tryInstantiateAll(data.possedeStations);
  }
}
