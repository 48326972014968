import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";
import { Zone } from "@models/data/zone";

export class Historique extends Model {
  dateDetection: DateModel;
  tonnage: bigint;
  apparientAZone: Zone;

  constructor(data: any) {
    super(data);
    this.dateDetection = ADAPTERS["DateModel"].parseIfPresent(
      data.dateDetection,
    ) as DateModel;
    this.tonnage = data.tonnage;
    this.apparientAZone = tryInstantiate(data.apparientAZone);
  }
}
