import { ConstructorType } from "@models/constructor-type";
import { Atelier } from "@models/data/atelier";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";
import { DechetAspect } from "@models/enums/dechet-aspect";

export class AtelierProfil extends Model {
    libelle: string;
    atelier: Atelier;
    producteurRaisonSociale: string;
    producteurVille: string;
    chantierIntitule: string;
    chantierCode: string;
    numeroDechetCAP: string;
    numeroDechetCED: string;
    dechetDesignation: string;
    aspect: DechetAspect;
    adrCode: string;
    adrEtiquette: string;
    adrEmballage: string;
    dechetPop: boolean;
    phrase1: string;
    phrase2: string;
    phrase3: string;
    phrase4: string;
    poids: number;
    nbConditionnement: number;

    constructor(data: ConstructorType<AtelierProfil>) {
        super(data);
        this.libelle = data.libelle;
        this.atelier = tryInstantiate<Atelier>(data.atelier);
        this.producteurRaisonSociale = data.producteurRaisonSociale;
        this.producteurVille = data.producteurVille;
        this.chantierIntitule = data.chantierIntitule;
        this.chantierCode = data.chantierCode;
        this.numeroDechetCAP = data.numeroDechetCAP;
        this.numeroDechetCED = data.numeroDechetCED;
        this.dechetDesignation = data.dechetDesignation;
        this.aspect = data.aspect;
        this.adrCode = data.adrCode;
        this.adrEtiquette = data.adrEtiquette;
        this.adrEmballage = data.adrEmballage;
        this.dechetPop = data.dechetPop;
        this.phrase1 = data.phrase1;
        this.phrase2 = data.phrase2;
        this.phrase3 = data.phrase3;
        this.phrase4 = data.phrase4;
        this.poids = data.poids;
        this.nbConditionnement = data.nbConditionnement;
    }
}
