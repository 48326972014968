import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Tag } from "@models/data/tag";

export class TagMoko extends Tag {
    macAddress: string;
    timestamp: string;
    lastDetection: DateModel | null;
    temperature: number | null;

    constructor(data: any) {
        super(data);
        this.macAddress = data.macAddress;
        this.timestamp = data.timestamp;
        this.lastDetection = ADAPTERS["DateModel"].parseIfPresent(
            data.lastDetection,
        ) as DateModel;
        this.temperature = data.temperature;
    }
}
