type Parameters = {
  id: string | null;
  __typename?: string;
};

export abstract class Model {
  id: string | null;
  __typename?: string;

  protected constructor({ id = null, __typename }: Parameters) {
    this.id = id;
    this.__typename = __typename;
  }
}
