import { LotTechnique } from "@models/data/lot-technique";
import { Model } from "@models/data/model";
import { tryInstantiateAll } from "@models/data/type-resolver";

export class Phrases extends Model {
  code: string;
  contientLotTechniques: LotTechnique[];

  constructor(data: any) {
    super(data);
    this.code = data.code;
    this.contientLotTechniques = tryInstantiateAll(data.contientLotTechniques);
  }
}
