import { Tag } from "@models/data/tag";

export class TagDraginoLds12 extends Tag {
    imei: string;
    firmware: string;
    batteryVoltage: number;

    constructor(data: any) {
        super(data);
        this.imei = data.imei;
        this.firmware = data.firmware;
        this.batteryVoltage = data.batteryVoltage;
    }
}
