import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { GroupeLotsTechnique } from "@models/data/groupe-lots-technique";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";
import { Zone } from "@models/data/zone";

export class Mouvement extends Model {
  dateDetection: DateModel;
  effectueGroupeLotsTechnique: GroupeLotsTechnique;
  provientZone: Zone;
  stockerDansZone: Zone;

  constructor(data: any) {
    super(data);
    this.dateDetection = ADAPTERS["DateModel"].parseIfPresent(
      data.dateDetection,
    ) as DateModel;
    this.effectueGroupeLotsTechnique = tryInstantiate(
      data.effectueGroupeLotsTechnique,
    );
    this.provientZone = tryInstantiate(data.provientZone);
    this.stockerDansZone = tryInstantiate(data.stockerDansZone);
  }
}
