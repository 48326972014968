import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { AlleyColumn } from "@models/data/alley-column";
import { GroupeLotsTechnique } from "@models/data/groupe-lots-technique";
import { LotTechnique } from "@models/data/lot-technique";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";
import { User } from "@models/data/user";
import { Zone } from "@models/data/zone";
import { AlerteStatus } from "@models/enums/alerte-status";
import { AlerteType } from "@models/enums/alerte-type";

export class Alerte extends Model {
    statut: AlerteStatus;
    dateDebut: DateModel;
    closingDate: DateModel | null;
    closingDescription: string | null;
    closingUser: User | null;
    typeDalerte: AlerteType;
    concerneGroupeLotsTechnique: GroupeLotsTechnique | null;
    dansZone: Zone;
    description: string;
    alleyColumn: AlleyColumn | null;
    lotTechnique: LotTechnique | null;

    constructor(data: any) {
        super(data);
        this.statut = data.statut;
        this.closingDate = data.closingDate === null ? null : ADAPTERS["DateModel"].parseIfPresent(
            data.closingDate,
        ) as DateModel;
        this.closingDescription = data.closingDescription;
        this.closingUser = data.closingUser === null ? null : tryInstantiate<User>(data.closingUser);
        this.dateDebut = ADAPTERS["DateModel"].parseIfPresent(
            data.dateDebut,
        ) as DateModel;
        this.typeDalerte = data.typeDalerte;
        this.concerneGroupeLotsTechnique = data.concerneGroupeLotsTechnique === null ? null : tryInstantiate<GroupeLotsTechnique>(
            data.concerneGroupeLotsTechnique,
        );
        this.dansZone = tryInstantiate(data.dansZone);
        this.description = data.description;
        this.alleyColumn = data.alleyColumn === null ? null : tryInstantiate<AlleyColumn>(data.alleyColumn);
        this.lotTechnique = data.lotTechnique === null ? null : tryInstantiate<LotTechnique>(data.lotTechnique);
    }
}
