import { Model } from "@models/data/model";
import { Station } from "@models/data/station";
import { tryInstantiateAll } from "@models/data/type-resolver";

export class BusinessStation extends Model {
  lieeStations: Station[];
  businessStationName: string;

  constructor(data: any) {
    super(data);
    this.lieeStations = tryInstantiateAll(data.lieeStations);
    this.businessStationName = data.businessStationName;
  }
}
