import { Model } from "@models/data/model";

export type ProfilEnum = "CLIENT" | "USER" | "ALL" | "GROUP" | "ORGANIZATION";
export type OperationType = "READ" | "CREATE" | "UPDATE" | "DELETE";
export type Operation = { values: OperationType[] };

export class Permission extends Model {
  profileOperations: Record<ProfilEnum, Operation>;
  permissionId: string;
  constructor(data: any) {
    super(data);
    this.profileOperations = data.profileOperations;
    this.permissionId = data.permissionId;
  }
}
