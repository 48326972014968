import { Group } from "@models/data/group";
import { Model } from "@models/data/model";
import { Role } from "@models/data/role";

import { Session } from "@models/data/session";
import { tryInstantiateAll } from "@models/data/type-resolver";
import { UserProperties } from "@models/enums/user-properties";

export class User extends Model {
  enabled: boolean;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  accountExpired: boolean;
  accountLocked: boolean;
  credentialsExpired: boolean;

  belongingGroups: Group[];
  sessions: Session[];
  properties: Record<UserProperties, string | null>;
  possedeRoles: Role[];

  constructor(data: any) {
    super(data);
    this.enabled = data.enabled;
    this.userName = data.userName;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.password = data.password;
    this.accountExpired = data.accountExpired;
    this.accountLocked = data.accountLocked;
    this.credentialsExpired = data.credentialsExpired;

    this.belongingGroups = tryInstantiateAll(data.belongingGroups);
    this.sessions = data.sessions ?? [];
    this.properties = data.properties;
    this.possedeRoles = tryInstantiateAll(data.possedeRoles);
  }
}
