import { Client } from "@models/data/client";
import { Group } from "@models/data/group";
import { Model } from "@models/data/model";
import { Tag } from "@models/data/tag";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";

export class Asset extends Model {
  manageTags: Tag[];
  client: Client;
  group: Group;
  assetName: string;

  constructor(data: any) {
    super(data);
    this.manageTags = tryInstantiateAll(data.manageTags);
    this.client = tryInstantiate(data.client);
    this.group = tryInstantiate(data.group);
    this.assetName = data.assetName;
  }
}
