import { Infrastructure } from "@models/data/infrastructure";
import { Manager } from "@models/data/manager";
import { Model } from "@models/data/model";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";

export class Site extends Model {
  code: string;
  name: string;
  description: string;
  possedeManagers: Manager[];
  possedeInfrastructure: Infrastructure;

  constructor(data: any) {
    super(data);
    this.code = data.code;
    this.name = data.name;
    this.description = data.description;
    this.possedeManagers = tryInstantiateAll(data.possedeManagers);
    this.possedeInfrastructure = tryInstantiate(data.possedeInfrastructure);
  }
}
