import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Asset } from "@models/data/asset";
import { DetectionEvent } from "@models/data/detectionevent";
import { Model } from "@models/data/model";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";

export class Tag extends Model {
  pairingStartTime: DateModel;
  lastDeviceId: string;
  manageAsset: Asset;
  lieeDetectionEvents: DetectionEvent[];
  tagName: string;
  isPaired: boolean;

  constructor(data: any) {
    super(data);
    this.pairingStartTime = ADAPTERS["DateModel"].parseIfPresent(
      data.pairingStartTime,
    ) as DateModel;
    this.lastDeviceId = data.lastDeviceId;
    this.manageAsset = tryInstantiate(data.manageAsset);
    this.lieeDetectionEvents = tryInstantiateAll(data.lieeDetectionEvents);
    this.tagName = data.tagName;
    this.isPaired = data.isPaired;
  }
}
