import { Flow } from "@models/data/flow";
import { Model } from "@models/data/model";
import { Site } from "@models/data/site";
import { tryInstantiateAll } from "@models/data/type-resolver";

export class Infrastructure extends Model {
  name: string;
  description: string;
  possedeSites: Site[];
  possedeFlows: Flow[];

  constructor(data: any) {
    super(data);
    this.name = data.name;
    this.description = data.description;
    this.possedeSites = tryInstantiateAll(data.possedeSites);
    this.possedeFlows = tryInstantiateAll(data.possedeFlows);
  }
}
