import { LotTechnique } from "@models/data/lot-technique";
import { Model } from "@models/data/model";
import { tryInstantiateAll } from "@models/data/type-resolver";

export class Chantier extends Model {
  intitule: string;
  codePostal: string;
  appartientALotTechniques: LotTechnique[];

  constructor(data: any) {
    super(data);
    this.intitule = data.intitule;
    this.codePostal = data.codePostal;
    this.appartientALotTechniques = tryInstantiateAll(
      data.appartientALotTechniques,
    );
  }
}
