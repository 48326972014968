import { AbstractAdapter, Adapter } from "@models/adapters/adapter";
import { optionalize } from "@shared/utils/object.utils";
import { DateTime } from "luxon";

class DateAdapter
  extends AbstractAdapter<DateModel>
  implements Adapter<DateModel>
{
  override parse = (value: string) => {
    const dateModel = fromIsoOptional(value);
    if (dateModel) return dateModel;
    throw new Error(`Couldn't parse a date : ${  value}`);
  };

  override serialize = (value: DateModel) => {
    const res = value.toISO();
    if (res) return res;
    throw new Error("Can't serialize the date object");
  };
}

export const dateAdapter = new DateAdapter();

export type DateModel = DateTime;
export const fromIsoOptional = optionalize(DateTime.fromISO);
