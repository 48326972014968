import { Model } from "@models/data/model";
import { tryInstantiateAll } from "@models/data/type-resolver";
import { Zone } from "@models/data/zone";

export class TypeZone extends Model {
  nom: string;
  estLieeAZones: Zone[];

  constructor(data: any) {
    super(data);
    this.nom = data.nom;
    this.estLieeAZones = tryInstantiateAll(data.estLieeAZones);
  }
}
