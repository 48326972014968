import { Flow } from "@models/data/flow";
import { Model } from "@models/data/model";
import { Site } from "@models/data/site";
import { Station } from "@models/data/station";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";

export class Manager extends Model {
  name: string;
  description: string;
  manageStations: Station[];
  manageFlow: Flow;
  possedeSite: Site;

  constructor(data: any) {
    super(data);
    this.name = data.name;
    this.description = data.description;
    this.manageStations = tryInstantiateAll(data.manageStations);
    this.manageFlow = tryInstantiate(data.manageFlow);
    this.possedeSite = tryInstantiate(data.possedeSite);
  }
}
