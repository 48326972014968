import { Model } from "@models/data/model";
import { Permission } from "@models/data/permission";
import { tryInstantiateAll } from "@models/data/type-resolver";
import { User } from "@models/data/user";

export class Role extends Model {
  name: string;
  permissions: { [objectName: string]: Permission };
  possedeUsers: User[];

  constructor(data: any) {
    super(data);
    this.name = data.name;
    this.permissions = data.permissions;
    this.possedeUsers = tryInstantiateAll(data.possedeUsers);
  }
}
