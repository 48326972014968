import { AbstractAdapter, Adapter } from "@models/adapters/adapter";

class JsonAdapter extends AbstractAdapter<object> implements Adapter<object> {
  override serialize = (json: object) => {
    return JSON.stringify(json);
  };
  override parse = (value: string | object) => {
    if (typeof value === "object") return value;
    return JSON.parse(value ?? "{}");
  };
}

export const jsonAdapter = new JsonAdapter();
