import { Infrastructure } from "@models/data/infrastructure";
import { Manager } from "@models/data/manager";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";

export class Flow extends Model {
  name: string;
  description: string;
  content: string;
  possedeInfrastructure: Infrastructure;
  manageManager: Manager;

  constructor(data: any) {
    super(data);
    this.name = data.name;
    this.description = data.description;
    this.content = data.content;
    this.possedeInfrastructure = tryInstantiate(data.possedeInfrastructure);
    this.manageManager = tryInstantiate(data.manageManager);
  }
}
