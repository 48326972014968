import { LotTechnique } from "@models/data/lot-technique";
import { Model } from "@models/data/model";
import { tryInstantiateAll } from "@models/data/type-resolver";

export class Producteur extends Model {
  code: string;
  raisonsociale: string;
  ville: string;
  disposeLotTechniques: LotTechnique[];

  constructor(data: any) {
    super(data);
    this.code = data.code;
    this.raisonsociale = data.raisonsociale;
    this.ville = data.ville;
    this.disposeLotTechniques = tryInstantiateAll(data.disposeLotTechniques);
  }
}
