import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { LotTechnique } from "@models/data/lot-technique";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";
import { DechetAspect } from "@models/enums/dechet-aspect";

export class Dechet extends Model {
  cap: string;
  bsd: string;
  ced: string;
  numReception: string;
  designation: string;
  dateReception: DateModel;
  aspect: DechetAspect;
  estComposeLotTechnique: LotTechnique;

  constructor(data: any) {
    super(data);
    this.cap = data.cap;
    this.bsd = data.bsd;
    this.ced = data.ced;
    this.numReception = data.numReception;
    this.designation = data.designation;
    this.dateReception = ADAPTERS["DateModel"].parseIfPresent(
      data.dateReception,
    ) as DateModel;
    this.aspect = data.aspect;
    this.estComposeLotTechnique = tryInstantiate(data.estComposeLotTechnique);
  }
}
