import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { AdR } from "@models/data/adr";
import { Atelier } from "@models/data/atelier";
import { Chantier } from "@models/data/chantier";
import { Dechet } from "@models/data/dechet";
import { GroupeLotsTechnique } from "@models/data/groupe-lots-technique";
import { Model } from "@models/data/model";
import { Phrases } from "@models/data/phrases";
import { Producteur } from "@models/data/producteur";
import { SiteTraitement } from "@models/data/site-traitement";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";
import { EtatLotTechnique } from "@models/enums/etat-lot-technique";

type RiskSummaryColumn = {
  code: string;
  label: string;
}

export class LotTechnique extends Model {
  zoneTraitement: string;
  tonnage: number;
  poidsAnnonce: number;
  codeConditionnement: string;
  etat: EtatLotTechnique;
  nbrConditionnement: bigint;
  libelleConditionnement: string;
  dateTraitement: DateModel | null;
  dateReception: DateModel;
  creationDate: DateModel;
  dechetPop: boolean;
  code: string;
  appartientAChantier: Chantier;
  disposeProducteur: Producteur;
  lieAAtelier: Atelier;
  phrases1: Phrases | null;
  phrases2: Phrases | null;
  phrases3: Phrases | null;
  phrases4: Phrases | null;
  estComposeDechets: Dechet[];
  estClasseDansAdR: AdR;
  contientGroupeLotsTechnique: GroupeLotsTechnique | null;
  siteTraitement: SiteTraitement;
  riskSummaryColumn: RiskSummaryColumn;

  constructor(data: any) {
    super(data);
    this.zoneTraitement = data.zoneTraitement;
    this.tonnage = data.tonnage;
    this.poidsAnnonce = data.poidsAnnonce;
    this.codeConditionnement = data.codeConditionnement;
    this.etat = data.etat;
    this.nbrConditionnement = data.nbrConditionnement;
    this.libelleConditionnement = data.libelleConditionnement;
    this.dateTraitement = data.dateTraitement === null ? null : ADAPTERS["DateModel"].parseIfPresent(
      data.dateTraitement,
    ) as DateModel;
    this.dateReception = ADAPTERS["DateModel"].parseIfPresent(
        data.dateReception
    ) as DateModel;
    this.creationDate = ADAPTERS["DateModel"].parseIfPresent(
        data.creationDate
    ) as DateModel;
    this.dechetPop = data.dechetPop;
    this.code = data.code;
    this.appartientAChantier = tryInstantiate(data.appartientAChantier);
    this.disposeProducteur = tryInstantiate(data.disposeProducteur);
    this.lieAAtelier = tryInstantiate(data.lieAAtelier);
    this.phrases1 = data.phrases1 === null ? null : tryInstantiate<Phrases>(data.phrases1);
    this.phrases2 =  data.phrases2 === null ? null : tryInstantiate<Phrases>(data.phrases2);
    this.phrases3 = data.phrases3 === null ? null : tryInstantiate<Phrases>(data.phrases3);
    this.phrases4 = data.phrases4 === null ? null : tryInstantiate<Phrases>(data.phrases4);
    this.estComposeDechets = tryInstantiateAll(data.estComposeDechets);
    this.estClasseDansAdR = tryInstantiate(data.estClasseDansAdR);
    this.contientGroupeLotsTechnique = data.contientGroupeLotsTechnique === null ? null : tryInstantiate<GroupeLotsTechnique>(
      data.contientGroupeLotsTechnique,
    );
    this.siteTraitement = tryInstantiate(data.siteTraitement);
    this.riskSummaryColumn = data.riskSummaryColumn;
  }
}
