import { ConstructorType } from "@models/constructor-type";
import { GroupeLotsTechnique } from "@models/data/groupe-lots-technique";
import { Inventaire } from "@models/data/inventaire";
import { Model } from "@models/data/model";
import { tryInstantiate } from "@models/data/type-resolver";
import { InventaireEntryStatus } from "@models/enums/inventaire-entry-status";

export class InventaireEntry extends Model {
    status: InventaireEntryStatus;
    groupeLotsTechnique: GroupeLotsTechnique;
    inventaire: Inventaire;

    constructor(data: ConstructorType<InventaireEntry>) {
        super(data);
        this.status = data.status;
        this.groupeLotsTechnique = tryInstantiate(data.groupeLotsTechnique);
        this.inventaire = tryInstantiate(data.inventaire);
    }
}
