import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Alerte } from "@models/data/alerte";
import { Alley } from "@models/data/alley";
import { Asset } from "@models/data/asset";
import { tryInstantiate, tryInstantiateAll } from "@models/data/type-resolver";

export class AlleyColumn extends Asset {
    name: string;
    threshold: number | null;
    lastDistanceCmDetection: number | null;
    lastDetectionDate: DateModel | null;
    alley: Alley;
    position: number | null;
    alertes: Alerte[];

    constructor(data: any) {
        super(data);
        this.name = data.name;
        this.threshold = data.threshold;
        this.lastDistanceCmDetection = data.lastDistanceCmDetection;
        this.alley = tryInstantiate<Alley>(data.alley);
        this.position = data.position;
        this.lastDetectionDate = ADAPTERS["DateModel"].parseIfPresent(data.lastDetectionDate) ?? null;
        this.alertes = tryInstantiateAll(data.alertes);
    }
}
