import { ADAPTERS } from "@models/adapters/adapter-resolver";
import { DateModel } from "@models/adapters/date-adapter";
import { Model } from "@models/data/model";
import { Station } from "@models/data/station";
import { Tag } from "@models/data/tag";
import { tryInstantiate } from "@models/data/type-resolver";

export class DetectionEvent extends Model {
  detectionTime: DateModel;
  lieeStation: Station;
  lieeTag: Tag;

  constructor(data: any) {
    super(data);
    this.detectionTime = ADAPTERS["DateModel"].parseIfPresent(
      data.detectionTime,
    ) as DateModel;
    this.lieeStation = tryInstantiate(data.lieeStation);
    this.lieeTag = tryInstantiate(data.lieeTag);
  }
}
